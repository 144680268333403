<template>

    <section>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                <form role="form">
                    <div class="row scrollable-content">
                        <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
                            <div class="table-box p-3 mb-3">
                                <div class="table-title d-flex mb-3 border-bottom pb-3">
                                    <button class="btn btn-purple me-3" @click.prevent="generateFileOutput('docx')"
                                        :disabled="invitationData.invitation_status === 'Invited' ? true : false">
                                        <span class="me-2">
                                            <font-awesome-icon :icon="['fas', `file-word`]"></font-awesome-icon>
                                        </span>
                                        <span>DOCX</span>
                                    </button>
                                    <button class="btn btn-purple me-3" @click.prevent="generateFileOutput('pdf')"
                                        :disabled="invitationData.invitation_status === 'Invited' ? true : false">
                                        <span class="me-2">
                                            <font-awesome-icon :icon="['fas', `file-pdf`]"></font-awesome-icon>
                                        </span>
                                        <span>PDF</span>
                                    </button>
                                    <button class="btn btn-purple me-3" @click.prevent="showDocumentPreview"
                                        :disabled="!data.dataList.preview_enabled">
                                        <span class="me-2">
                                            <font-awesome-icon :icon="['fas', `eye`]"></font-awesome-icon>
                                        </span>
                                        <span>PREVIEW</span>
                                    </button>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <p class="mb-3"><span class="fw-bold">Document Name:</span>
                                            <span class="text-break">{{ data.dataList.name }}</span>
                                        </p>
                                        <p class="mb-3"><span class="fw-bold">Description:</span> <span class="text-break">{{ data.dataList.description }}</span>
                                        </p>
                                        <p class="mb-3"><span class="fw-bold">Authors:</span>&nbsp;
                                            {{ data.dataList.authors  }}
                                        </p>
                                        <p class="mb-3 ">
                                            <span class="fw-bold">Content:</span>
                                        </p>
                                        <div class="text-break project_document_view_v_html_1 clearfix" v-html="data.dataList.content"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                            <div class="table-box p-3 mb-3">
                                <div class="comment-panel">
                                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                                        <li class="nav-item w-50 active" role="presentation">
                                            <button class="nav-link w-100 active" id="usercard-tab" data-bs-toggle="tab"
                                                    data-bs-target="#usercard" type="button" role="tab"
                                                    aria-controls="usercard"
                                                    aria-selected="true">
                                                <font-awesome-icon icon="id-card-alt"/>
                                            </button>
                                        </li>
                                        <li class="nav-item w-50" role="presentation">
                                            <button class="nav-link w-100" id="attachment-tab" data-bs-toggle="tab"
                                                    data-bs-target="#attachment" type="button" role="tab"
                                                    aria-controls="attachment"
                                                    aria-selected="false">
                                                <font-awesome-icon icon="paperclip"/>
                                            </button>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane show active" id="usercard" role="tabpanel"
                                             aria-labelledby="usercard-tab">
                                            <div class="comment-ctl text-break">
                                                <div v-if="data.dataList.explanation" class="border-bottom mb-2 text-center sm-text"
                                                    v-html="data.dataList.explanation"> </div>
                                            </div>
                                            
                                        </div>
                                        <div class="tab-pane fade" id="attachment" role="tabpanel"
                                             aria-labelledby="attachment-tab">
                                             <div class="comment-ctl">
                                                 <span v-if="data.dataList.links">
                                                     <div class="border-bottom mb-2 text-center sm-text"
                                                        v-html="data.dataList.links" > </div>
                                                 </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
    import HelperFunction from '@/common/helpers';
    import {computed, onMounted} from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import {useStore} from "vuex";
    export default {
        name: "ProjectDocumentView",
        setup() {
            const {data, getAllData, downloadFile,downloadDocxFile} = HelperFunction();
            const route = useRoute();
            const router = useRouter();
            const store = useStore();
            const invitationData = computed(() => store.getters.getProjectInvitationData);

            let setParams = {
                project_id: route.params.id,
                document_id: route.params.doc
            };

            data.setURL = vueConfig.Project.ProjectEndPoint + '/documents/' + setParams.document_id;

            onMounted(async () => {
                getAllData(data.setURL, setParams);
            });

            async function generateFileOutput(type) {
                let payloadUrl = vueConfig.Project.ProjectEndPoint + '/documents/generate';
                let payload = {
                    action: payloadUrl,
                    filename: `${data.dataList.name.replace(' ', '_')}.${type}`,
                    FormData: {
                        document_id: setParams.document_id,
                        generate: type,
                    }
                };
                if(type == 'docx'){
                    await downloadDocxFile(payload)
                    .then((resp) => {
                        Export2Word(resp.data, payload.filename)
                    });
                }else{
                    await downloadFile(payload)
                        .then((resp) => {
                            const url = URL.createObjectURL(new Blob([resp.data], {
                                type: 'application/vnd.ms-excel'
                            }))
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', payload.filename);
                            document.body.appendChild(link);
                            link.click();
                        });
                }
                
            }

            let Export2Word = (element, filename = '') => {
                let preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
                let postHtml = "</body></html>";
                let html = preHtml+element+postHtml;

                let blob = new Blob(['\ufeff', html], {
                    type: 'application/msword'
                });
                const url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
                filename = filename?filename+'.doc':'document.doc';
                const downloadLink = document.createElement("a");
                document.body.appendChild(downloadLink);
                if(navigator.msSaveOrOpenBlob ){
                    navigator.msSaveOrOpenBlob(blob, filename);
                }else{
                    downloadLink.href = url;
                    downloadLink.download = filename;
                    downloadLink.click();
                }
                document.body.removeChild(downloadLink);
            }

            const showDocumentPreview = () => {
                router.push({
                    name: "document-preview"
                });
            }

            return {
                data, 
                setParams, 
                downloadFile, 
                generateFileOutput, 
                invitationData,
                showDocumentPreview
            }
        }
    }
</script>

<style scoped>

    /*project_document_view_v_html design*/
    .project_document_view_v_html_1 >>> table tbody td, .project_document_view_v_html_1 >>> table tbody th{
        border: 1px solid black;
        border-collapse: collapse;
    }
    .project_document_view_v_html_1 >>> strong {
        color: black;
    }
    .project_document_view_v_html_1 >>> h1, .project_document_view_v_html_1 >>> h2,
    .project_document_view_v_html_1 >>> h3, .project_document_view_v_html_1 >>> h4,
    .project_document_view_v_html_1 >>> h5, .project_document_view_v_html_1 >>> h6,
    .project_document_view_v_html_1 >>> p, .project_document_view_v_html_1 >>> a,
    .project_document_view_v_html_1 {
        font-family: sans-serif;
    }
    .comment-ctl {
        height: calc(130vh - 45em) !important;
        max-height: calc(130vh - 45em) !important;
    }


</style>